<template>
  <secondary-page-layout :info-link="true" :product="product" :title="title">
    <div class="agreement-item">
      <div v-if="agreement" class="head-agreement">
        <h1 class="title">
          <span v-if="['1000', '1025', '6611'].includes(agreement.insrType)">{{ $t('Стоимость страховки') }}</span>
          <span v-else>{{ $t('Страховая сумма') }}</span>
        </h1>
        <p v-if="['1000', '1025', '6611'].includes(agreement.insrType)" class="price"> {{ agreement.amount | priceFormat }} ₸</p>
        <p v-else class="price"> {{ agreement.coverValue | priceFormat }} {{ coverValueCurrency }}</p>
      </div>

      <v-dialog content-class="alert_change-card" width="300"/>

      <div class="info-card">
        <div class="item">
          <div class="title-wrap">
            <div class="icon">
              <icon icon-name="calendar-icon"></icon>
            </div>
            <p>{{ $t('Дата оформления') }}</p>
          </div>
          <p v-if="agreement" class="info">{{ beginDate }}</p>
        </div>
        <div class="item">
          <div class="title-wrap">
            <div class="icon">
              <icon icon-name="calendar-icon"></icon>
            </div>
            <p>Дата завершения</p>
          </div>
          <p v-if="agreement" class="info">{{ endDate }} </p>
        </div>
      </div>

      <div class="navigate-agreement">
        <div v-if="agreement" class="wrap-items">
          <router-link v-if="agreement.canEdit && agreement.insrType === '1000'" :to="{name: 'OgpoExtension', params: {id: agreement.id}}"
                       class="link-item"
                       @click.native="prolongationEvent(agreement)">
            <div class="icon-block">
              <icon icon-name="extend-policy"></icon>
            </div>
            <p>{{ $t('extend') }}</p>
          </router-link>

          <router-link v-if="agreement.canEdit && canExtend"
                       :to="{name: 'insurance.products', params: {id: currentPolicyId, form_component: currentPolicyFormComponent }, query: {agreement_id: agreement.id}}" class="link-item"
                       @click.native="prolongationEvent(agreement)">
            <div class="icon-block">
              <icon icon-name="extend-policy"></icon>
            </div>
            <p>{{ $t('extend') }}</p>
          </router-link>


          <a v-if="agreement.canEdit && canChange" class="link-item" @click="changingEvent(agreement)">
            <div class="icon-block">
              <icon icon-name="change-policy"></icon>
            </div>
            <p>{{ $t('changePolicy') }}</p>
          </a>

          <router-link class="link-item" :to="{name: 'agreements.pdf', params: {agreement: agreement.id}, query: {title}}">
            <div class="icon-block">
              <icon icon-name="download"></icon>
            </div>
            <p>Скачать</p>
          </router-link>
<!--          <a class="link-item" @click="downloadAgreement">-->
<!--            <div class="icon-block">-->
<!--              <icon icon-name="download"></icon>-->
<!--            </div>-->
<!--            <p>Скачать</p>-->
<!--          </a>-->


          <router-link v-if="isActive && agreement.canEdit" :to="{name: 'sos.instruction', params: { id: sosId }}"
                       class="link-item">
            <div class="icon-block">
              <icon icon-name="my-accs"></icon>
            </div>
            <p>Подать на выплату</p>
          </router-link>
        </div>
      </div>
      <ul v-if="agreement" class="list-agreement">
        <li class="item">
          <span class="title">{{ $t('Программа') }}</span>
          <span class="info">“{{ agreement.productName }}”</span>
        </li>
        <li class="item">
          <span class="title">Страхователь</span>
          <span class="info">{{ agreement.holder.title }}</span>
        </li>
        <template v-for="(item, index) in agreement.objects" v-if="agreement.objects">
          <!-- Номера машин-->
          <template v-if='"1000" == agreement.insrType || "2220" == agreement.insrType'>
            <li class="item">
              <span class="title">Под защитой</span>
              <span class="info">{{ item.license_plate }} {{ item.model }} {{ item.title }}</span>
            </li>
          </template>

          <!--Адрес-->
          <template v-if='agreement.insrType == "3011" || "3012" == agreement.insrType'>
            <li class="item">
              <span class="title">Под защитой</span>
              <span class="info">{{ item.street_name }} {{ item.blockNumber }}<span
                  v-if="item.apartmentNumber">, {{ item.apartmentNumber }}</span></span>
            </li>
          </template>
        </template>

        <template v-if='["3011", "3017"].includes(agreement.insrType)'>
          <li class="item">
            <span class="title">Возместим Вам до</span>
            <span class="info">1 000 000 ₸</span>
          </li>
          <li class="item">
            <span class="title">Возместим соседям до</span>
            <span class="info">500 000 ₸</span>
          </li>
          <li class="item">
            <span class="title">Стоимость страховки</span>
            <span class="info">{{ agreement.amount | priceFormat }} ₸</span>
          </li>
        </template>
        <template v-if='["3012", "3019"].includes(agreement.insrType)'>
          <li class="item">
            <span class="title">Возместим Вам до</span>
            <span class="info">2 000 000 ₸</span>
          </li>
        </template>

      </ul>
    </div>
  </secondary-page-layout>
</template>
<script>
import UnsubscribeModal from "@/components/Modals/UnsubscribeModal";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import eventLogger from '../../services/eventLogger';

export default {
  name: "AgreementItem",
  data() {
    return {
      product: {},
      data_success: {
        title: this.$i18n.t('paymentCardSuccessfully_1'),
        subtitle: this.$i18n.t('paymentCardSuccessfully_2'),
      },
      agreement: null,
      type: null,
      policies: null,
      currentPolicy: null,
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'authUser',
      accessToken: 'accessToken'
    }),
    sosId() {
      if (['1000', '2220', '2221', '2225'].includes(this.agreement.insrType)) {
        return 1;
      } else if (['3011', '3012', '3014', '3016', '3017', '3019'].includes(this.agreement.insrType)) {
        return 2;
      } else if (['9100'].includes(this.agreement.insrType)) {
        return 3;
      } else if (['6230', '6231'].includes(this.agreement.insrType)) {
        return 4
      } else if (['6610', '6611'].includes(this.agreement.insrType)) {
        return 5
      }
    },
    currentPolicyId() {
      return this.currentPolicy?.id
    },
    currentPolicyFormComponent() {
      return this.currentPolicy?.form_component
    },
    apiUrl() {
      return process.env.VUE_APP_BACKEND_URL
    },
    title() {
      if (this.agreement) {
        return this.agreement.productName;
      } else {
        return '';
      }
    },
    policyId() {
      return this.$route.params.agreement || '';
    },
    beginDate() {
      if (this.agreement) {
        return moment(this.agreement.beginDate.date).format("DD.MM.YYYY");
      } else {
        return '';
      }
    },
    endDate() {
      if (this.agreement) {
        return moment(this.agreement.endDate.date).format("DD.MM.YYYY");
      } else {
        return '';
      }
    },
    canExtend() {
      return  this.currentPolicy && this.agreement.insrType !== '9100' && this.isPolicyActual && this.agreement.insrType !== '1000' && this.agreement.insrType !== '6650'
    },
    canChange() {
      return  this.isPolicyActual && this.isActive && this.agreement.insrType === '1000' && [0, 11, 12].includes(this.agreement.state)
    },
    isPolicyActual(){
      return ['1000', '2220', '2221', '2225', '3011', '3012', '3014', '3016', '3017', '3019', '9100', '6230', '6231', '6610', '6611'].includes(this.agreement.insrType)
    },
    isActive() {
      return moment(this.agreement.endDate.date) >= moment();
    },
    coverValueCurrency() {
      let c = this?.agreement?.coverValueCurrency

      if (c === 'USD') {
        return '$'
      } else if (c === 'EUR') {
        return '€'
      } else {
        return '₸';
      }
    }
  },
  created() {
    this.setDefaultInfo();
    this.getProduct();

  },
  methods: {
    ...mapActions({
      changePhoneNumber: 'itemSubscription/changePhoneNumber',
      changeHolder: 'itemSubscription/changeHolder',
      changeInsureds: 'itemSubscription/changeInsureds',
      changeTariff: 'itemSubscription/changeTariff',
      changeManId: 'itemSubscription/changeManId',
      changeItemSub: 'itemSubscription/changeItemSub',
    }),
    async setDefaultInfo() {
      const loader = this.$loading.show();
      try {
        const {data} = await window.axios.get(`policies/${this.$route.params.agreement}`);
        this.agreement = data;

        this.changePhoneNumber(this.agreement.holder.contacts.MOBILE);
        this.changeHolder(this.agreement.holder);
        this.changeItemSub(this.agreement);
        if (this.agreement.insureds) {
          this.changeInsureds(this.agreement.insureds);
        }

      } catch (e) {
        console.log('data >', e);
      }
      console.log(moment(this.agreement.endDate.date) >= moment())
      loader.hide();
    },
    async getProduct() {
      const {data} = await window.axios.get(`/insurance-products/${this.$route.params.id}`);
      this.product = data;
      this.currentPolicy = data;
    },
    changingEvent(agreement) {
      if (agreement.state == 0) {
        this.$modal.show('dialog', {
          title: 'Уважаемый клиент!',
          text: 'Переоформление можно сделать после 20:00 на следующий день после оформления',
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          }]
        });
        return;
      } else {
        this.$modal.show('dialog', {
          title: 'Уважаемый клиент!',
          text: 'При любом изменении страхового полиса, ранее начисленные бонусы будут аннулированы!',
          buttons: [{
            title: 'OK',
            handler: () => {
              eventLogger('select_change_policy', {policy_type: agreement.insrType})

              this.$router.push({
                name: 'OgpoReinsurance', params: {id: agreement.id}
              })
              return null;
            }
          }]
        });
      }
    },
    openChangeCard() {
      this.$modal.show('dialog', {
        title: this.$i18n.t('loyalClientModalTitle'),
        text: this.$i18n.t('yourCardDebited'),
        buttons: [{
          title: this.$i18n.t('good'),
          handler: () => {
            this.$modal.hide('dialog');
            this.changeCard();
            return null;
          }
        },
          {
            title: this.$i18n.t('cancel'),
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          },
        ]
      });
    },
    async downloadAgreement() {
      let loader = this.$loading.show({});
      try {
        await window.axios.get(`policies/download/${this.agreement.id}?key=${this.agreement.key}`)
        loader.hide()
        let fileLink = document.createElement('a');

        fileLink.href = `${this.apiUrl}/storage/${this.agreement.id}.pdf`;

        fileLink.click();
      } catch (err) {
        throw err
      }
    },
    //  Сменить карту
    async changeCard() {
      try {
        let loader = this.$loading.show();
        const {data} = await window.axios.post(`/subscription/update-token`, {
          policy_id: this.policyId,
          token: this.accessToken
        });
        this.$router.push({name: 'agreement.verify', params: {data_success: this.data_success}});
        loader.hide();
      } catch (e) {
        console.log(e);
      }
    },
    openUnsubscribe() {
      this.$modal.show(UnsubscribeModal, {policy_id: this.policyId, product_id: this.product.id}, this.$modalConfig);
    },
    prolongationEvent(agreement) {
      eventLogger('select_extend policy', {policy_type: agreement.insrType})
    }
  }
}
</script>
<style lang="scss">
.agreement-item {
  padding: 0 16px;

  .head-agreement {
    padding: 0px 24px;
    margin-bottom: 16px;

    .title {
      font-family: 'Manrope';
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #566681;
      margin-bottom: 16px;
    }

    .price {
      font-family: 'Manrope';
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: #071222;
      margin-bottom: 0px;
    }
  }


  .info-card {
    background: #EDEDED;
    border-radius: 12px;
    padding: 14px 0px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    .item {
      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }

      .info {
        color: #071222;
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-bottom: 0px;
      }

      .title-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        padding: 0px;

        p {
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #566681;
          margin-bottom: 0px;
        }

        .icon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #E1F4EE;
          padding: 2px;

          &.red {
            background: #F8DFE8;
          }
        }
      }
    }
  }


  .navigate-agreement {
    /*display: flex;
    justify-content: space-between;*/

    padding: 12px 24px;
    background: #FFFFFF;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    margin-bottom: 30px;

    .wrap-items {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      flex-wrap: wrap;

      &:nth-child(2) {
        margin-top: 32px;
      }

      .col-wrap-items {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &:first-child {
          /*margin-bottom:32px;*/
        }
      }
    }

    .link-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20%;

      .icon-block {
        margin-bottom: 4px;
      }

      p {
        font-family: 'Manrope';
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #071222;
        margin-bottom: 0px;
      }
    }

    .empty-item {
      display: flex;
      width: 20%;
    }
  }


}
</style>